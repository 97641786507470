/* hide the recaptcha badge, need to add links to forms */
.grecaptcha-badge {
  visibility: hidden;
}

/* todo, remove tailwind */

/* Import tailwind base styles */
@tailwind base;

/* Import tailwind component styles */
@tailwind components;

/* Import tailwind utility styles */
@tailwind utilities;

.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6,
.prose blockquote {
  color: #243e63;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  line-height: 1.4;
  font-size: 100%;
}

* {
  box-sizing: border-box;
}

.osano-cm-widget-special {
  position: fixed;
  bottom: 65px;
  right: 15px;
}
